<template>
  <v-dialog
    persistent
    class="b-dialog"
    transition="dialog-bottom-transition"
    :retain-focus="false"
    :value="dialog"
    :width="history ? 'auto' : 400"
    @input="closeModal(false)"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon id="btn-close" name="btn-close" @click="closeModal(false)">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="b-dialog--content pa-0 py-2" v-if="history">
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="filteredHeaders"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template
            v-slot:[`item.planName`]="{ item }"
            v-if="planTableType === 'Faixa etária'"
          >
            {{ item.planName }}
            <v-tooltip bottom v-if="item.positions">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary" small>
                  fa-solid fa-circle-info
                </v-icon>
              </template>
              <span>Cargos associados: {{ item.positions }}.</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.effectiveDate`]="{ item }">
            {{ formatDate(item.effectiveDate) }}
          </template>
          <template v-slot:[`item.readjustmentPercentage`]="{ item }">
            {{ formatPercentage(item.readjustmentPercentage) }}
          </template>
          <template v-slot:[`item.value`]="{ item }">
            {{ formatMoney(item.value) }}
          </template>
          <template v-slot:[`item.priceLives0To18`]="{ item }">
            {{ formatMoney(item.priceLives0To18) }}
          </template>
          <template v-slot:[`item.priceLives19To23`]="{ item }">
            {{ formatMoney(item.priceLives19To23) }}
          </template>
          <template v-slot:[`item.priceLives24To28`]="{ item }">
            {{ formatMoney(item.priceLives24To28) }}
          </template>
          <template v-slot:[`item.priceLives29To33`]="{ item }">
            {{ formatMoney(item.priceLives29To33) }}
          </template>
          <template v-slot:[`item.priceLives34To38`]="{ item }">
            {{ formatMoney(item.priceLives34To38) }}
          </template>
          <template v-slot:[`item.priceLives39To43`]="{ item }">
            {{ formatMoney(item.priceLives39To43) }}
          </template>
          <template v-slot:[`item.priceLives44To48`]="{ item }">
            {{ formatMoney(item.priceLives44To48) }}
          </template>
          <template v-slot:[`item.priceLives49To53`]="{ item }">
            {{ formatMoney(item.priceLives49To53) }}
          </template>
          <template v-slot:[`item.priceLives54To58`]="{ item }">
            {{ formatMoney(item.priceLives54To58) }}
          </template>
          <template v-slot:[`item.priceLives59To120`]="{ item }">
            {{ formatMoney(item.priceLives59To120) }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-form
        @submit.prevent="save"
        ref="form"
        lazy-validation
        v-model="valid"
        v-else
      >
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <BaseAutonumeric
                label="Percentual de reajuste"
                id="readjustmentPercentage"
                name="readjustmentPercentage"
                suffix="%"
                outlined
                required
                :rules="[required]"
                v-model="model.readjustmentPercentage"
              />
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <BaseDatePicker
                id="readjustmentEffectiveDate"
                name="readjustmentEffectiveDate"
                label="Data da vigência"
                required
                :rules="[required]"
                v-model="model.readjustmentEffectiveDate"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="b-dialog--actions"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        >
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            :disabled="!valid"
            title="Salvar"
          />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';
import {
  formatMoney,
  formatPercentage,
  formatDate
} from '@/helpers/formatting';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    history: {
      type: Boolean,
      required: true,
      default: false
    },
    planTableType: {
      type: String,
      required: true
    },
    hasEligibility: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String
    },
    contractId: {
      type: String
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    options: {},
    valid: true,
    model: {
      readjustmentPercentage: null,
      readjustmentEffectiveDate: null
    }
  }),

  computed: {
    filteredHeaders() {
      let type = this.planTableType === 'Faixa etária';
      let widthPrices = type ? 150 : 'auto';

      let headers = [
        { text: 'Plano', value: 'planName', width: type ? 300 : 'auto' },
        {
          text: 'Reajuste',
          value: 'readjustmentPercentage',
          width: type ? 120 : 'auto'
        },
        {
          text: 'Vigência',
          value: 'effectiveDate',
          width: type ? 120 : 'auto'
        },
        { text: 'Cargos', value: 'positions' },
        { text: 'Valor', value: 'value' },
        { text: '0-18', value: 'priceLives0To18', width: widthPrices },
        { text: '19-23', value: 'priceLives19To23', width: widthPrices },
        { text: '24-28', value: 'priceLives24To28', width: widthPrices },
        { text: '29-33', value: 'priceLives29To33', width: widthPrices },
        { text: '34-38', value: 'priceLives34To38', width: widthPrices },
        { text: '39-43', value: 'priceLives39To43', width: widthPrices },
        { text: '44-48', value: 'priceLives44To48', width: widthPrices },
        { text: '49-53', value: 'priceLives49To53', width: widthPrices },
        { text: '54-58', value: 'priceLives54To58', width: widthPrices },
        { text: '59-120', value: 'priceLives59To120', width: widthPrices }
      ];

      if (!this.hasEligibility) {
        headers = headers.filter(header => header.value !== 'positions');
      }

      if (type) {
        headers = headers.filter(
          header => header.value !== 'value' && header.value !== 'positions'
        );
      } else {
        headers = headers.filter(header => {
          return header.value.includes('priceLives') === false;
        });
      }

      return headers;
    }
  },

  methods: {
    formatMoney,
    formatPercentage,
    formatDate,

    closeModal(search) {
      if (!this.history) {
        this.$refs.form.reset();
      }

      this.$emit('closeModal', search);
    },

    async search() {
      if (this.history) {
        try {
          const { page, itemsPerPage } = this.options;
          const contractService = new ContractService();
          const { status, data } =
            await contractService.readjustmentPlanHistory({
              page,
              take: itemsPerPage,
              contractId: this.contractId,
              customerId: this.customerId
            });

          if (status === 200) {
            this.items = data.results;
            this.totalRecords = data.totalRecords;
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const contractService = new ContractService();
        const { status } = await contractService.readjustmentPlan({
          contractId: this.contractId,
          customerId: this.customerId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
